import React from "react";
import Page from "../components/Page";
import Grid from "@mui/material/Grid";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Button from "../elements/Button";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/home");
  };

  return (
    <Page>
      <Grid item xs={12}>
        <div className="bg-primary flex flex-col relative">
          <div className="relative overflow-none bg-gradient-to-r from-primary to-white">
            <img
              src="/hero.jpg"
              alt="Футбольна гра"
              className="w-full h-[340px] md:h-[440px] object-cover"
            />
            <div className="absolute left-0 top-0 h-full w-full flex flex-col items-center justify-center">
              <div className="flex py-8 md:py-12 flex-col items-center justify-between h-full">
                <h2 className="uppercase text-2xl text-white border-white border bg-primary px-4 py-2 rounded-xl">
                  Футбольні прогнози УПЛ
                </h2>
                <div className="bg-gold p-2 uppercase text-primary rounded-md font-bold">
                  Весняний турнір
                </div>
                <Button onClick={goToHome}>
                  <ExitToAppIcon />
                  &nbsp;Перейти до гри
                </Button>
              </div>
            </div>
          </div>
          <div className="bg-white p-4">
            <p className="text-black mb-4">
              <strong>ФУТБАТЛ</strong> - це турнір футбольних прогнозів{" "}
              <u>без букмекерів і грошових ставок</u>, який робить перегляд
              футболу ще більш цікавим.
            </p>
            <h3 className="text-primary m-0 uppercase font-bold mb-4 border-b-2 border-primary">
              Як грати?
            </h3>
            <p className="text-black mb-4">
              ✅ Прогнозуйте рахунки футбольних матчів УПЛ
            </p>

            <p className="text-black mb-4">
              ✅ Перемагайте своїх суперників кожного туру
            </p>

            <p className="text-black mb-4">
              ✅ Отримуйте бали та очолюйте турнірну таблицю ліги
            </p>

            <h3 className="text-primary m-0 uppercase font-bold mb-4 border-b-2 border-primary">
              Регламент турніру
            </h3>

            <p className="text-black mb-4">
              ✅ Усі учасники, які зареєструвалися у грі, надсилають свої
              прогнози на тур УПЛ. Дедлайном кожного туру{" "}
              <strong>є час старту першого матчу </strong> туру.
            </p>

            <p className="text-black mb-4">
              ✅ Усі учасники, які відправили хоча б один прогноз до дедлайну
              туру, беруть участь{" "}
              <strong>у жеребкуванні, яке визначає суперника на тур </strong>{" "}
              для кожного учасника гри.
            </p>
            <p className="text-black mb-4">
              ✅ За перемогу в дуелі (батлі) учасник отримує{" "}
              <strong>3 очки</strong>, за нічию <strong>1 очко</strong>.
            </p>
            <p className="text-black mb-4">
              ✅ У разі рівності очок у турнірній таблиці, вище буде той
              учасник, який матиме перевагу: за різницею забитих та пропущених
              м'ячів, кількістю забитих м'ячів{" "}
            </p>

            <h3 className="text-primary m-0 uppercase font-bold mb-4 border-b-2 border-primary">
              Розрахунок дуелі
            </h3>
            <p className="text-black mb-4">
              ✅ Дуель (батл) - це матч між двома учасниками турніру, які
              прогнозують однаковий набір футбольних матчів.
            </p>
            <p className="text-black mb-4">
              ✅ Учасникам дуелі пропонується спрогнозувати точний рахунок
              футбольного матчу. Прогнози на футбольні матчі відправляються
              заздалегідь та є недоступними до перегляду іншими користувачами аж
              до початку конкретного футбольного матчу, на який було відправлено
              прогноз.
            </p>
            <p className="text-black mb-4">
              ✅ Змінювати прогноз на конкретний футбольний матч можна безліч
              разів до завершення дедлайну - запланованого часу початку
              футбольного матчу.{" "}
            </p>
            <p className="text-black mb-4">
              ✅ Розрахунок результату дуелі здійснюється шляхом послідовного
              порівняння прогнозів двох учасників.
            </p>
            <p className="text-black mb-4">
              ✅ Взяття воріт (гол) зараховується за наступними правилами:
              <ul className="mt-2">
                <li>
                  1️⃣ точно спрогнозовано <strong>рахунок матчу</strong>{" "}
                </li>{" "}
                <li>
                  2️⃣ правильно спрогнозовано <strong>результат матчу</strong>{" "}
                  (перемога, нічия, поразка){" "}
                  <strong>на відміну від суперника</strong>
                  <br />
                  <br />
                  <i>
                    Наприклад, якщо <strong>Учасник 1</strong> спрогнозував
                    рахунок <strong>2:0</strong>, а <strong>Учасник 2</strong>{" "}
                    спрогнозував рахунок <strong>1:1</strong>, то після
                    завершення матчу з рахунком <strong>3:1</strong>,{" "}
                    <strong>Учасник 1</strong> отримає +1 бал в дуелі (батлі).
                  </i>
                  <br />
                  <br />
                </li>
                <li>
                  3️⃣ правильно спрогнозовано{" "}
                  <strong>різницю м'ячів у рахунку </strong>{" "}
                  <strong>на відміну від суперника </strong>
                  <br />
                  <br />
                  <i>
                    Наприклад, якщо <strong> Учасник 1</strong> спрогнозував
                    рахунок <strong>2:0 (+2)</strong>, а{" "}
                    <strong>Учасник 2</strong> спрогнозував рахунок{" "}
                    <strong>3:0 (+3)</strong>, то після завершення матчу з
                    рахунком <strong>3:1 (+2)</strong>,{" "}
                    <strong>Учасник 1</strong> отримає +1 бал в дуелі (батлі).
                  </i>
                </li>
              </ul>
            </p>
            <p className="text-black mb-4">
              ✅ Дивіться приклад розрахунку дуелі на картинці нижче (ліворуч та
              праворуч - прогнози учасників, по центру - результат футбольного
              матчу)
            </p>
            <img className="w-full" alt="" src="/battle-example.png"></img>
            <div className="flex items-center justify-center">
              <Button onClick={goToHome}>
                <ExitToAppIcon />
                &nbsp;Перейти до гри
              </Button>
            </div>
          </div>
        </div>
      </Grid>
    </Page>
  );
};

export default Landing;
