import React, { useEffect, useState } from "react";
import Page from "../components/Page";
import { fetchDuels } from "../actions/predictions";
import { connect } from "react-redux";
import Navigation from "../components/Navigation";
import Loader from "../components/Loader";
import DuelDialog from "../components/DuelDialog";
import API from "../api";
import { CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import TocIcon from "@mui/icons-material/Toc";
import { getDisplayedName } from "./RoundDuels";
import { getShownRound } from "../utils";

export const getPlayerInfoById = (users = [], id) => {
  return users.find((player) => player.UserId === id);
};

export const getShortPlayerName = (fullName = "") => {
  const chunks = fullName.split(" ");
  if (chunks.length > 1) {
    return chunks[0].at(0) + ". " + chunks[1];
  } else {
    return fullName;
  }
};

const PlayerDuels = ({
  duels = [],
  teams = [],
  fetchDuels,
  loaded,
  players = [],
  gameNumber,
}) => {
  const [duelDialog, setDuelDialog] = useState(false);
  const [duelInfo, setDuelInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastDuelId, setLastDuelId] = useState(null);

  let { userId } = useParams();
  const navigate = useNavigate();

  const openDuelDialog = () => setDuelDialog(true);
  const handleCloseDuelDialog = () => setDuelDialog(false);

  useEffect(() => {
    if (!userId) return;
    window.scrollToTopDisabled = false;
    fetchDuels({ userId });
  }, [fetchDuels, userId]);

  const title = <>Батли</>;

  const fetchDuel = async (duelId) => {
    try {
      setLoading(true);
      setLastDuelId(duelId);
      const response = await API.fetchDuel(duelId);
      setDuelInfo(response.data);
      openDuelDialog();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page classes="h-[100%]">
      <Navigation
        title={title}
        info={
          <span
            className="flex items-center cursor-pointer mr-4"
            onClick={() => navigate("/standings")}
          >
            <TocIcon />
            &nbsp;Таблиця ліги
          </span>
        }
      />
      {loaded ? (
        <div className="mt-10">
          {duels.length === 0 && (
            <div className="bg-gold text-primary p-2 flex justify-start items-center">
              <InfoIcon />
              &nbsp;Поки що немає батлів для відображення
            </div>
          )}
          {duels.map((duel) => (
            <div
              key={duel.DuelId}
              className="uppercase px-3 pb-1 pt-2 flex justify-between bg-white text-primary"
            >
              <div
                onClick={() => navigate(`/roundDuels/${duel.Round}`)}
                className="w-8 h-8 flex items-center border border-gold justify-center bg-gold text-primary mr-2 cursor-pointer hover:bg-primary hover:text-gold hover:border-primary"
              >
                {getShownRound(duel.Round)}
              </div>
              <div className="flex w-full items-center justify-between">
                <div
                  onClick={() => navigate(`/playerDuels/${duel.FirstPlayer}`)}
                  className={`cursor-pointer flex flex-row items-center hover:decoration-solid flex-[0_1_195px] text-left ${
                    userId === duel.FirstPlayer ? "font-bold" : ""
                  }`}
                  data-id={duel.FirstPlayer}
                >
                  <img
                    src={getPlayerInfoById(players, duel.FirstPlayer)?.Logo}
                    alt=""
                    className="h-8 w-8 rounded-full object-cover mr-1"
                  />
                  {getDisplayedName(
                    getPlayerInfoById(players, duel.FirstPlayer)?.FullName
                  )}
                </div>
                <div
                  className={`${
                    duel.Round < gameNumber
                      ? `bg-red text-white`
                      : `bg-gold text-primary`
                  } cursor-pointer hover:bg-primary hover:text-gold h-8 text-xl font-bold flex-[0_1_56px] text-center flex items-center justify-center`}
                  onClick={() => {
                    fetchDuel(duel.DuelId);
                  }}
                >
                  {loading && duel.DuelId === lastDuelId ? (
                    <CircularProgress size={16} className="text-white" />
                  ) : (
                    <>
                      {duel.FirstPlayerScore}:{duel.SecondPlayerScore}
                    </>
                  )}
                </div>
                <div
                  onClick={() => navigate(`/playerDuels/${duel.SecondPlayer}`)}
                  className={`cursor-pointer flex flex-row items-center justify-end hover:decoration-solid flex-[0_1_195px] text-right ${
                    userId === duel.SecondPlayer ? "font-bold" : ""
                  }`}
                  data-id={duel.SecondPlayer}
                >
                  {getDisplayedName(
                    getPlayerInfoById(players, duel.SecondPlayer)?.FullName
                  )}
                  <img
                    src={getPlayerInfoById(players, duel.SecondPlayer)?.Logo}
                    alt=""
                    className="h-8 w-8 rounded-full object-cover ml-1"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="h-[calc(100vh-64px)] bg-white flex items-center justify-center">
          <Loader />{" "}
        </div>
      )}
      <DuelDialog
        open={duelDialog}
        handleClose={handleCloseDuelDialog}
        duelInfo={duelInfo}
        teams={teams}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  duels: state.duel,
  loaded: state.app.loaded,
  teams: state.teams,
  players: state.players,
  me: state.user.me,
  gameNumber: state.user.gameNumber,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDuels: (options) => dispatch(fetchDuels(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerDuels);
