import React, { useEffect } from "react";
// import PlayerInfo from "../components/PlayerInfo";
import Page from "../components/Page";
import UserInfo from "../components/UserInfo";
import MyPredictions from "../components/MyPredictions";
import Head from "../components/Head";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import MyDuel from "../components/MyDuel";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import { getShownRound } from "../utils";

const Home = ({
  loaded,
  me,

  gameNumber,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollToTopDisabled = false;
  }, [me]);

  if (me && me?.Registered === 0) {
    navigate("/registration");
  }

  const hasDuel = me?.duel?.DuelId;

  return (
    <Page classes="h-[100%]">
      <div
        id="player-panel"
        className="fixed w-full top-16 z-[999] bg-primary text-gold pb-2 max-w-screen-sm border-t-4 border-double border-white"
      >
        <UserInfo loaded={loaded} />

        <Head
          loaded={loaded}
          icon={hasDuel ? null : <HourglassBottomIcon className="!text-2xl" />}
          action={<MyDuel duel={me?.duel} hasDuel={hasDuel} />}
        />

        <Head
          onClick={() => navigate("/roundDuels/" + gameNumber)}
          icon={<OnlinePredictionIcon className="!text-2xl" />}
          loaded={loaded}
          text={
            me?.Active ? `Раунд ${getShownRound(gameNumber)}` : "Гру завершено"
          }
        />
      </div>
      <MyPredictions hasDuel={hasDuel} loaded={loaded} meActive={me?.Active} />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  loaded: state.app.loaded,
  me: state.user.me,
  gameNumber: state.user.gameNumber,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
